/*-----------------------------------------------------*/
.albumWrapperDiv{
    border:0.4rem solid var(--dpItemBorder);
    margin:auto;
    max-width:95%;
    text-align: center;
    margin-top:1.6rem;
    margin-bottom:1.6rem;
    /* padding-top:1rem;
    padding-bottom:1rem; */
    background-color: var(--mainColor);
    border-radius: 1.5rem;
    box-shadow: 0px 0px 1rem var(--dpOutline);
    padding:0.8rem
}

.albumDiv{
    border:2px solid var(--dpItemBorder);
    padding: 1.6rem;
    border-radius:1.5rem;
    background-color: var(--dpBrown);
    color: var(--dpSecondText);
    box-shadow:0px 0px 0.3rem var(--dpOutline);
    margin:0.5rem !important;
}

.albumDiv div{
    font-size: 1.0rem;
}

.albumDiv:hover{
    background-color:#182828;
}


.albumDivAlbumTitle, .albumDivAlbumCopyright{
    padding-top:0.5rem;
    padding-bottom:0.5rem;
    padding-left:0.2rem;
    padding-right:0.2rem;
}

.albumDiv img {
    /* width:10rem;
    height:10rem; */
    width:90%;
    height:90%;
    /* object-fit: contain; */
}

.albumDiv a {
    color: var(--dpSecondText);
    text-decoration: none;
}

/* // x-Small devices (landscape phones, 576px and up) */
@media (min-width: 0px) and (max-width: 575px) {
    #linkDivHolder ul li a {
      font-size: 1.25rem;
    }
    #linkDivHolder label {
      font-size: 1.2rem;
    }

    .albumDiv div{
        font-size: 2.05rem;
     } 
  }
  
  /* // Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) and (max-width: 767px) {
    #linkDivHolder ul li a {
      font-size: 1.25rem;
    }
    #linkDivHolder label {
      font-size: 1.2rem;
    }

    .albumDiv div{
        font-size: 1.25rem;
     } 
  }
  
  /* // Medium devices (tablets, 768px and up) */
  @media (min-width: 768px )and (max-width: 991px) {
    .albumDiv div{
        font-size: 1.25rem;
    
    } 
  }
  
  /* // Large devices (desktops, 992px and up) */
  /* // X-Large devices (large desktops, 1200px and up) */
  /* // XX-Large devices (larger desktops, 1400px and up) */
  @media (min-width: 992px) {
  
  }
