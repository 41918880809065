/* ORIGINAL BUTTON AND AUDIO COLORS #d5eebb */

pre{
    word-wrap: normal;
    white-space: pre-wrap;
}

/*-----------------------------------------------------*/
/* Container for the entire audio player including tracks, controls and album info  */
.audioPlayerContainerDivWrapper {
    padding: 1rem;
    padding-bottom: 0;
}

.audioPlayerContainerDiv{
    max-width: 100% !important;
    text-align: center;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    background-color: var(--dpBrown);
    border-radius: 1.5rem;
    box-shadow: 0px 0px 0.7rem var(--dpOutline);
    border: 5px solid var(--dpItemBorder);
}

.audioPlayerContainerSection  {
    border: 5px solid var(--dpItemBorder) !important;
    margin: auto !important;
    max-width: 95% !important;
    text-align: center !important;
    margin-top: 1rem !important;
    padding-top: 1rem !important;
    background-color: var(--mainColor) !important;
    border-radius: 1.5rem !important;
    box-shadow: 0px 0px 0.7rem var(--dpOutline) !important;
}

/*-----------------------------------------------------*/
/*---- Music Wrapper ----*/
/* wrapper for the entire audio player, controls and album info only  */
#musicPlayerWrapper {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

.musicPlayer {
    border-radius: 1.5rem;
    background-color: rgba(255, 255, 255, 0.08);
    /* box-shadow: 0 40px 100px rgba(255,255,255,0.1); */
    padding: 1rem;
    overflow: hidden;
    /* color:var(--dpAudioColor); */
    color: black;
}

/*-----------------------------------------------------*/
/*---- DisplayTracks.js ----*/
/*---- Title, Artist and Image ----*/
.artistName,
.albumTitle,
.musicTitle {
    text-align: center;
    text-transform: capitalize;
    text-shadow: var(--dpAudioColor) 0px 0px 1.5rem;
    font-size: 2rem;
    font-weight: bold;
}

.albumArt img {
    position: relative;
    display: block;
    margin: 1rem auto;
    width: 20rem;
    height: 20rem;

    width: 90%;
    height: 90%;

    border-radius: 1.5rem;

    background-size: cover;
    box-shadow: 0 0 0 0.6rem rgba(255, 255, 255, 0.08);
}

.albumArtRound {
    animation: rotate 20s linear infinite;
    border-radius: 50% !important;
}

.albumArt.play {
    animation-play-state: running;
}

.addHole::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: var(--dpBrown);
    z-index: 1;
    border: 1px solid var(--dpItemBorder);
    background-color: var(--dpBrown);
}

@keyframes rotate {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* // x-Small devices (landscape phones, 576px and up) */
@media (min-width: 0px) and (max-width: 575px) {
    .artistName,
    .albumTitle,
    .musicTitle {
        font-size: 2.5rem;
    }
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767px) {
    .artistName,
    .albumTitle,
    .musicTitle {
        font-size: 3.5rem;
    }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991px) {
}

/* // Large devices (desktops, 992px and up) */
/* // X-Large devices (large desktops, 1200px and up) */
/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 992px) {
}

/*-----------------------------------------------------*/
/*---- Progress Bar ----*/
.sliderWrapper {
    margin-bottom: 2.5rem;
}

.songSlider {
    width: 100%;
    position: relative;
}

.songSliderSeek {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 5px;
    border-radius: 0.6rem;
    cursor: pointer;
    background-color: #9a905d;
}

.songSliderSeek::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 1px;
    height: 1.5rem;
    background: #000;
    /* box-shadow: -400px 0 0 400px #d5eebb; */
}

.songCurrentTime,
.songDuration {
    position: absolute;
    padding-top: 0.4rem;
    font-size: 1rem;
}

.songCurrentTime {
    left: 0;
}

.songDuration {
    right: 0;
}

/* =========================
Custom Input range 
=============================*/

/* Input range - chrome and safari */

input[type='range'] {
    --range-progress: 0;
    -webkit-appearance: none;
    position: relative;
    background: #ccc;
    width: 100%;
    height: 0.3rem;
    border-radius: 2px;
    cursor: pointer;
}

/* Input range - firefox */
input[type='range']::-moz-range-track {
    position: relative;
    background: #ccc;
    width: 100%;
    height: 0.3rem;
    border-radius: 2px;
    cursor: pointer;
}

/* played progress length - Chrome & safari*/
input[type='range']::before {
    content: '';
    height: 0.3rem;
    background: var(--dpAudioColor);
    width: var(--range-progress);
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    position: absolute;
    top: 0;
    left: 0;
}

/* played progress length - firefox */
input[type='range']::-moz-range-progress {
    background: var(--dpAudioColor);
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    height: 2px;
}

/* slider thumb - chrome and safari */
input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 1.1rem;
    width: 1.1rem;
    border-radius: 50%;
    border: none;
    background-color: var(--dpAudioColor);
    cursor: pointer;
    position: relative;
}

/* dragging thumb - chrome and safari */
input[type='range']:active::-webkit-slider-thumb {
    transform: scale(1.2);
}

/* slider thumb - firefox */
input[type='range']::-moz-range-thumb {
    height: 1.1rem;
    width: 1.1rem;
    border-radius: 50%;
    background: var(--dpAudioColor);
    cursor: pointer;
    border: transparent;
    position: relative;
}
/* dragging thumb - firefox */
input[type='range']:active::-moz-range-thumb {
    transform: scale(1.2);
}

/* // x-Small devices (landscape phones, 576px and up) */
@media (min-width: 0px) and (max-width: 575px) {
    .songCurrentTime,
    .songDuration {
        font-size: 1.25rem;
    }
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767px) {
    .songCurrentTime,
    .songDuration {
        font-size: 1.5rem;
    }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991px) {
}

/* // Large devices (desktops, 992px and up) */
/* // X-Large devices (large desktops, 1200px and up) */
/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 992px) {
}

/*-----------------------------------------------------*/
/*---- Music Controls ----*/
.controlsWrapper {
}

.musicControls {
    width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    margin-top: 2rem;
}

.btnPlay {
    position: relative;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background: var(--dpAudioColor);
    cursor: pointer;
    border: none;
}

.btnPlay span {
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translateY(-50%);
    width: 10px;
    height: 30px;
    border-radius: 2px;
    background: #5f7a61;
    transition: 0.5s;
    clip-path: polygon(0 0, 100% 1%, 100% 100%, 0% 100%);
}

.btnPlay span:nth-child(2) {
    left: 55%;
    transform-origin: center;
}

.btnPlay.pause span:nth-child(2) {
    transform: translateY(-50%) scaleY(0);
}

.btnPlay.pause span:nth-child(1) {
    width: 35%;
    left: 53%;
    transform: translate(-50%, -50%);
    border-radius: 0;
    clip-path: polygon(0 0, 100% 50%, 0% 100%);
}

.btnPlay.pause {
    animation: pulse 2s linear infinite;
}

@keyframes pulse {
    0% {
        box-shadow: 0;
    }
    50% {
        box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.1);
    }
    100% {
        box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.4);
    }
}

.btn {
}

.btnBackward {
}

.btnForward {
}

.BTN {
    width: 3rem;
    height: 3rem;
    background: var(--dpAudioColor);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
}

/*---- volume ----*/

.volume {
    /* margin:0;
    padding:0; */
    width: 25%;
    margin-top: 0.8em;
}

.volume button svg {
    font-size: 20px;
    color: var(--dpAudioColor);
}

.volume {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 0;
}

.volume button {
    border: none;
    background-color: transparent;
    margin-right: 8px;
    cursor: pointer;
    margin: 0;
    padding: 0;
}

/* // x-Small devices (landscape phones, 576px and up) */
@media (min-width: 0px) and (max-width: 575px) {
    .musicControls {
        width: 75%;
    }

    .btnPlay {
        width: 4.5rem;
        height: 4.5rem;
    }

    .BTN {
        width: 3.5rem;
        height: 3.5rem;
    }
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767px) {
    .musicControls {
        width: 65%;
    }

    .btnPlay {
        width: 5.5rem;
        height: 5.5rem;
    }

    .BTN {
        width: 4.5rem;
        height: 4.5rem;
    }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991px) {
    .musicControls {
        width: 80%;
    }
}

/* // Large devices (desktops, 992px and up) */
/* // X-Large devices (large desktops, 1200px and up) */
/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 992px) {
}

/*-----------------------------------------------------*/
/*---- Tracks ----*/
.trackListingDiv div {
    font-size: 1.25rem;
}

.trackWrapper {
    border: 0.1rem solid#222;
    border-bottom: none;
    margin: auto;
    color: var(--dpAudioColor);
}

.trackWrapper:last-child {
    border-bottom: 1px solid#222;
    margin-bottom: 0.5rem;
}

.albumTracksDiv {
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: black;
    /* color:var(--dpSecondText); */
}

.divBorder {
    /* border: 1px solid#222 */
}

.textLeft {
    text-align: left;
    padding-left: 0.3rem;
}

.textCenter {
    text-align: left;
}

.textRight {
    text-align: right;
    padding-right: 0.3rem !important;
}

.selectedTrack {
    background-color: var(--dpAudioColor);
    color: #1c1e1b;
    color: black;
    font-weight: 600;
}

/* // x-Small devices (landscape phones, 576px and up) */
@media (min-width: 0px) and (max-width: 575px) {
    .trackListingDiv div {
        font-size: 1.5rem;
    }
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767px) {
    .trackListingDiv div {
        font-size: 1.75rem;
    }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991px) {
    .trackListingDiv div {
        font-size: 1.35rem;
    }
}

/* // Large devices (desktops, 992px and up) */
/* // X-Large devices (large desktops, 1200px and up) */
/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 992px) {
    .trackListingDiv div {
        font-size: 1.5rem;
    }
}

/*-----------------------------------------------------*/
/*---- Album Informaiton ----*/

.albumInformationDiv {
    padding-bottom: 0.5rem;
}

.albumInformationDiv div {
    font-size: 1.1rem;
    font-weight: bold;
}

.albumInformation {
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: var(--dpSecondText);
    color: black;
}

.buyMeButton {
    background-color: #4caf50; /* Green */
    background-color: var(--dpAudioColor);
    border: none;
    color: white;
    color: var(--dpBrown);
    padding: 0.3rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.1rem;
    border-radius: 0.7rem;
    animation: pulseBuy 2s linear infinite;
    margin-left: 0.5rem;
}

@keyframes pulseBuy {
    0% {
        box-shadow: 0;
    }
    50% {
        box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
    }
    100% {
        box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.4);
    }
}

.buyMeButton span {
    font-size: 1.2rem;
    color: black;
    font-weight: bold;
    text-transform: uppercase;
}

.buyMeButton:hover {
    background-color: red;
    background-color: #4caf50; /* Green */
    color: white;
}

/* // x-Small devices (landscape phones, 576px and up) */
@media (min-width: 0px) and (max-width: 575px) {
    .albumInformationDiv div {
        font-size: 1.6rem;
    }

    .buyMeButton {
        font-size: 1.5rem;
    }

    .buyMeButton span {
        font-size: 1.2rem;
    }
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767px) {
    .albumInformationDiv div {
        font-size: 1.6rem;
    }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991px) {
    .albumInformationDiv div {
        font-size: 1.25rem;
    }
}

/* // Large devices (desktops, 992px and up) */
/* // X-Large devices (large desktops, 1200px and up) */
/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 992px) {
}

/* 

#holdRecordPlayer{
    position:relative;
}

#rpaDiv{
    position:absolute;
    top:2rem;
    right: -1.7rem;
    background-image: url("../../Assets/images/MusicPlayer/RecordPlayerArm.png");
    background-size:contain;

    width:12rem;
    height:15rem;
    background-repeat:no-repeat;
    z-index:10;

    transform: rotate(-5deg);
    
} */
