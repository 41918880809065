.mainNav{
    background-color: var(--mainColor);
    color: var(--textColor);
}

.dpDotCom, .dpDotCom:hover{
  color: var(--dpSecondText);
  text-shadow: var(--dpBrown) 0px 0px 5px;
  font-size:1.5rem;
}

.dpDotCom span a{
  position:relative;
  padding:0;
  margin:0;
}

.dpDotComA:hover{
  color: var(--dpSecondText);
  text-shadow: var(--dpBrown) 0px 0px 5px;
  background-color: var(--mainColor);
  font-size:1.5rem;
  text-align: left;
}

/* .dpDotComA{
  color: var(--dpSecondText);
  text-shadow: var(--dpBrown) 0px 0px 5px;
  font-size:1.5rem;
  text-align: left;
} */


.active{
    color:var(--dpSecondText);
    background-color: var(--dpBrown);
    text-transform: uppercase;
    border-radius: 10px;
}

nav a {
    margin:0 1.1rem;
    color: var(--textColor);
    text-decoration: none;
    padding: 0.5rem;
    font-size:1.2rem;
}

nav a:hover {
    position:relative;
    color: white;
    background-color:#5a615a;
    border-radius: 10px;
}

nav a.bandcamp, nav a.youtube{
  position:relative;
  top:0.15rem;
  margin: 0rem 0.5rem;
  color: var(--textColor);
  color: #fffacd;
 
  text-decoration: none;
  padding: 0.0rem;
  font-size:rem;  
}

nav a.bandcamp svg, nav a.youtube svg{
  transition: all .2s ease-in-out; 
  font-size:2rem;
}


nav a.bandcamp:hover, nav a.youtube:hover{
  position:relative;
  /* color: var(--dpBrown); */
  background-color:transparent;
  transform: scale(1.4); 
}

.nBarToggler{
    /* width: 500px !important;
    height:300px !important; */
    height:4rem;
    width:4rem;   
  }

  .nBarTogglerIcon{
    height:2rem;
    width:2rem;
  }

  .nBarNav{
    margin:0.6rem;
  }

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 0px) and (max-width: 574px) {
  nav a.bandcamp svg, nav a.youtube svg{
    position: relative;
    left:-30px;
    font-size:2.5rem;
  }
}

/* // x-Small devices (landscape phones, 576px and up) */
@media (min-width: 0px) and (max-width: 620px) {
  nav a {
    margin:0 0.2rem;
  }

  /* nav a.bandcamp svg, nav a.youtube svg{
    position: relative;
    left:-80px;
    font-size:3rem;
  } */

  /* nav a.youtube svg{
    left:-160px;
  }*/
} 


/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 621px) and (max-width: 800px) {
    nav a {
      margin:0 0.9rem;
    }
  }


/* // Medium devices (tablets, 768px and up) (max-width: 991px)*/
@media (min-width: 801px )and (max-width: 1060px) {
    nav a {
        margin:0 1.5rem;
    }
  }


/* // Large devices (desktops, 992px and up) */
/* // X-Large devices (large desktops, 1200px and up) */
/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1061px) {
    nav a {
        margin:0 2.0rem;
    }
  }