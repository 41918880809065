.footerDiv {
    /* position: fixed; */
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: var(--mainColor);
    color: white;
    text-align: center;
 }

 .copyright{
    padding:0.4rem;
    text-align: center;
    font-size: 1.0rem;
    font-weight: bold;
 }

 .tooltip {
   font-size:1.0rem;
   /* padding:1.0rem; */
   font-weight: bold;
 }

 .tooltip button {
   font-weight: bold;
   padding:1.0rem;
 }


 /* // x-Small devices (landscape phones, 576px and up) */
@media (min-width: 0px) and (max-width: 575px) {
   .tooltip button {
      font-weight: bold;
      padding:0.6rem;
      font-size:1.0rem;
      line-height: 1.5rem;
    }
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767px) {
   .tooltip button {
      font-weight: bold;
      padding:0.8rem;
      font-size:1.25rem;
      line-height: 2.0rem;
    }
}

 /* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px )and (max-width: 991px) {
   .tooltip button {
      font-weight: bold;
      padding:0.8rem;
      font-size:1.25rem;
      line-height: 2.0rem;
    }
 }