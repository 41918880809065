.errorDiv{
    position: relative;
}

.errorDiv h1{
    position: absolute;
    top: 3.0rem;
    left:6.0rem;
    color:white;
    color:var(--dpAudioColor);
    /* text-shadow: var(--dpBrown) 0px 0px 1.5rem; */
}

.errorDiv h2{
    position: absolute;
    top: 8.0rem;
    left:8.0rem;
    color:white;
    color:var(--dpAudioColor);
   /* text-shadow: var(--dpBrown) 0px 0px 1.5rem; */
}

.errorDiv h3{
    font-weight: 900;
    display:inline;
    color:white;
}

.errorPath{
    font-size:2rem;
    position: absolute;
    bottom: 2.0rem;
    width:100%;
    text-align: center;
    color:var(--dpAudioColor);
   /* text-shadow: var(--dpBrown) 0px 0px 1.5rem; */
}


.errorDiv .errorImg{
    display: block;

}

.errorImg{
    width: 100%;
}


/* // x-Small devices (landscape phones, 576px and up) */
@media (min-width: 0px) and (max-width: 575px) {
    .errorDiv h1{
        font-size:1.5rem;
        top: 1.5rem;
        left:2.0rem;
    }

    .errorDiv h2{
        font-size:1.25rem;
        top: 5.0rem;
        left:4.0rem;
    }
    
    .errorPath{
        font-size:1.1rem;
    }
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767px) {

    .errorPath{
        font-size:1.5rem;
    }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991px) {
}

/* // Large devices (desktops, 992px and up) */
/* // X-Large devices (large desktops, 1200px and up) */
/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 992px) {
    .errorDiv h2{
        top: 9.0rem;
        left:8.0rem;
    }
    .errorImg{
        width: 100%;
        max-height:600px;
    }
}
