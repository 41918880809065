@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap');

#root {
    --dpColorBackground: #d2b48c; /* Tan */

    --mainColor: #6e766e; /* Olive */

    --textColor: #eee;
    --dpSecondText: #f5deb3; /* Burlywood */

    --dpBrown: #7a5c4b;
    --dpBrown: #2f4f4f; /*DarkSlateGray*/

    --dpOutline: #1c1e1b; /* Outline */
    --dpItemBorder: #7e8879; /* Border Color */

    --dpAudioColor: #eee8aa; /* PaleGoldenrod */
    --dpAudioColor: #fffacd;

    --siteBGColor: #ffffff; /*used as site background color*/

    --mainWhiteColor: #ffffff;
    /* --mainColor: #29335C; */

    --mainColorlight: #5767aa;
    --secondaryColor: red;
    --hoverColor: orange;

    /*jjd lose it*/
    /* background-color: crimson;  */
    background-color: #182828;
}


html {
    /* internal padding for the entire web page  */
    /* padding:10px; */
    /* background-color: var(--secondaryColor); */
    /* background-color: #ffffff; */
    /* background-color:orange; */

}

* {
    font-family: 'Titillium Web', sans-serif;
    line-height: 150%;
    /* font-weight:600; */
    font-size: 16px;
}


#root, html{
    background-color: #182828;
    background-color: #ffffff;
    animation-name: color;
    animation-duration: 10s;
    animation-iteration-count: infinite;
}

@keyframes color {
    0% {
        background-color: #182828;
        background-color:#7e8879;
        background-color: #ffffff;
    }
    50% {
        background-color:#7e8879;
    }
    100% {
        background-color: #182828;
        background-color:#7e8879;
        background-color: #ffffff;
    }
  }


.site-card {
    /* background-color: white; */
    background-color: var(--dpColorBackground);
    /* background-color:transparent; */
    /* padding:5px; */
}

body {
    margin: 0.8rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

/* // x-Small devices (landscape phones, 576px and up) */
@media (min-width: 0px) and (max-width: 575px) {
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767px) {
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991px) {
}

/* // Large devices (desktops, 992px and up) */
/* // X-Large devices (large desktops, 1200px and up) */
/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 992px) {
}

/* // X-Small devices (portrait phones, less than 576px) */
/* @media (min-width: 0px) and (max-width: 575px) {

} */

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 0px) and (max-width: 767px) {
    #root {
        /* background-color: pink; */
    }

    html {
        /* background-color: pink; */
    }

    * {
        font-size: 0.68rem;
    }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991px) {
    #root {
        /* background-color: red; */
    }

    html {
        /* background-color: red; */
    }

    * {
        font-size: 0.8rem;
    }
}

/* // Large devices (desktops, 992px and up) */
/* // X-Large devices (large desktops, 1200px and up) */
/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 992px) {
    #root {
    }

    html {
    }

    * {
        font-size: 1rem;
    }
}
