#linkDiv{
    /* background-color:#0f0; */
}

#linkDivHolder{
    padding:1vh;
    text-align: left;
    margin:auto;
    /* background-color: red; */
}

#linkDivHolder label {
    color: var(--dpOutline);
    padding:0px;
    font-size: 1.0rem;
    font-weight:bold;
  }

  #linkDivHolder ul {
    /* border:1px solid #0f0; */

  }

#linkDivHolder ul li {
    list-style-type: none;
    margin-left: -1.9rem;
    padding: 0;
    /* border:1px solid #0f0; */
  }

  #linkDivHolder ul li a {
    text-decoration: none;
    /* color:var(--textColor); */
    color:var(--dpSecondText);
    font-size: 0.8rem;
    left:0px;
    /* border:1px solid #0f0; */
  }

  #linkDivHolder ul li a:hover {
    color:var(--textColor);
    /* color:var(--dpSecondText) */
  }

  .center {
    margin: auto;
    /* text-align: center; */
  }

  .myColor:hover{
    color: red;
  }


/* // x-Small devices (landscape phones, 576px and up) */
@media (min-width: 0px) and (max-width: 575px) {
  #linkDivHolder ul li a {
    font-size: 1.05rem;
  }
  #linkDivHolder label {
    font-size: 1.1rem;
  }
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767px) {
  #linkDivHolder ul li a {
    font-size: 1.25rem;
  }
  #linkDivHolder label {
    font-size: 1.2rem;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px )and (max-width: 991px) {
  #linkDivHolder ul li a {
    font-size: 1.0rem;
  }
  #linkDivHolder label {
    font-size: 1.2rem;
  }
}

/* // Large devices (desktops, 992px and up) */
/* // X-Large devices (large desktops, 1200px and up) */
/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 992px) {

}