/* ---- Instruments ---- */
.instrumentDivWrapper{
    padding:1.5rem;
}

.instrumentContainer{
    border:0.4rem solid var(--dpItemBorder);
    border-radius:1.5rem;
    background-color: var(--dpBrown);
    padding-bottom:2rem;
    box-shadow: 0px 0px 1rem var(--dpOutline);
}

.instrumentDiv{
    border:0.4rem solid var(--dpItemBorder);
    margin:auto;
    max-width:80%;
    text-align: center;
    margin-top:1.5rem;;
    padding-top:1rem;
    padding-bottom:1rem;
    background-color: var(--mainColor);
    border-radius: 1.5rem;
    box-shadow: 0px 0px 1.5rem var(--dpOutline);
}


.instrumentDiv:hover{
    transition: transform 2.0s;
    transform: scale(1.30)
 }

.instrumentDiv:nth-last-child(2) {
    margin-bottom:1.5rem;
  }

.img{
    border: 0.3rem solid var(--dpItemBorder);
    box-shadow: 0px 0px 1.5rem var(--dpOutline);
    max-width:80%;
    border-radius: 1.5rem;
}

.instrumentTitle{
    margin-top:0.5rem;
    color:var(--textColor);
    font-size:1.1rem;
    text-shadow: #000 0px 0px 0.8rem;
}

.instrumentDescription{
    color: var(--dpSecondText);
    font-size:1rem;
    text-align: justify;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    text-shadow: #222 1px 0 0.3rem;
}

.hidden{
    display:none;
}

.instrumentDiv div{
    font-size:1.25rem;
}

/* // x-Small devices (landscape phones, 576px and up) */
@media (min-width: 0px) and (max-width: 575px) {
    .instrumentDiv div{
        font-size:1.65rem;
    }
  }
  
  /* // Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) and (max-width: 767px) {
    .instrumentDiv div{
        font-size:1.75rem;
    }
  }
  
  /* // Medium devices (tablets, 768px and up) */
  @media (min-width: 768px )and (max-width: 991px) {
    .instrumentDiv div{
        font-size:1.50rem;
    }
  }
  
  /* // Large devices (desktops, 992px and up) */
  /* // X-Large devices (large desktops, 1200px and up) */
  /* // XX-Large devices (larger desktops, 1400px and up) */
  @media (min-width: 992px) {
  
  }
