/* ---- BIO ---- */
#artistInfoWrapper{
    padding:1.5rem;
}

#artistInfoContainer{
    border:0.4rem solid var(--dpItemBorder);
    border-radius:1.5rem;
    background-color: var(--dpBrown);
    /* padding-bottom:2rem; */
    box-shadow: 0px 0px 1rem var(--dpOutline);
}

.artistImage{
    max-width:20rem;
    max-height:20rem;

    min-width:4rem;
    min-height:4rem;

    max-width:15rem;
    max-height:15rem;

    min-width:4rem;
    min-height:4rem;

    margin:auto;
    padding:0.5rem;;

    flex-direction: column;

    outline: 1px solid var(--mainColor);
    outline-offset: -0.5rem;

    margin-top:0.6rem;
}

.flexbox {
    display: flex;
    align-items: center;
    justify-content: center;
  }


.artistInfo_Name_Location div{
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem;
    /* margin-top:0.8rem; */
    margin-bottom:0.8rem;
    color: var( --textColor);
    text-shadow: var(--dpOutline) 0px 0px 0.8rem;
}

.textJustify{
    text-align :justify;
    vertical-align: middle;
}

.artistBio{
    overflow-wrap: break-word;
    white-space: break-spaces;
    color:var(--dpSecondText);
    font-size: 1.1rem;
}

/* // x-Small devices (landscape phones, 576px and up) */
@media (min-width: 0px) and (max-width: 575px) {
    .artistBio{
        font-size: 1.25rem;
    }
  }
  
  /* // Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) and (max-width: 767px) {
    .artistBio{
        font-size: 1.5rem;
    }
  }
  
  /* // Medium devices (tablets, 768px and up) */
  @media (min-width: 768px )and (max-width: 991px) {
    .artistBio{
        font-size: 1.5rem;
    }
  }
  
  /* // Large devices (desktops, 992px and up) */
  /* // X-Large devices (large desktops, 1200px and up) */
  /* // XX-Large devices (larger desktops, 1400px and up) */
  @media (min-width: 992px) {
  
  }