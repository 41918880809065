.banner-wrapper {
    display: flex;
}

#banner-img {
    border-top-right-radius:12px;
    border-top-left-radius:12px;
    width: 100%;
    object-fit:scale-fill;
    z-index:0;
}
