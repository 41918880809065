/* ---- HOME PAGE - Artist Release information 'pill' ---- */
.homeDivWrapper{
    padding:1.0rem;
    padding-bottom:0;
}

.homeContainer{
    border:5px solid #7e8879; 
    border-radius:1.5rem;
    background-color:  #2F4F4F;
    padding-bottom:2rem;
    box-shadow: 0px 0px 0.7rem var(--dpOutline);
    vertical-align: middle;
    padding:0px;
}

.artistLatestReleaseInfomationDiv{
    padding:1rem;
    font-size:1.5rem;
    color:var(--dpSecondText);
    text-align: center;
}

.artistLatestReleaseInfomationDiv h1{
    font-size:1.5rem;
}




/* // x-Small devices (landscape phones, 576px and up) */
@media (min-width: 0px) and (max-width: 575px) {
    .artistLatestReleaseInfomationDiv{
        font-size:1.25rem;
     }
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767px) {

  }
  
  /* // Medium devices (tablets, 768px and up) */
  @media (min-width: 768px )and (max-width: 991px) {

  }
  
  /* // Large devices (desktops, 992px and up) */
  /* // X-Large devices (large desktops, 1200px and up) */
  /* // XX-Large devices (larger desktops, 1400px and up) */
  @media (min-width: 992px) {

  }
