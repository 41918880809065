.divSocialMediaNav{
    background-color: var(--mainColor);
    height: 3.0rem;
    /* border:1px solid #0f0; */
    text-align:center;
    padding:0.3rem;
}

.divSocialMediaNav a {
    /* position:relative; */
    top:0.15rem;
    margin: 0 2.0rem;
    color: #fffacd;
    text-decoration: none;
}

a svg{
    transition: all .2s ease-in-out; 
    font-size:2rem;
}
  
a svg:hover{
    /* position:relative; */
    /* color: var(--dpBrown); */
    background-color:transparent;
    transform: scale(1.4); 
}
  